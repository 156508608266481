import Shell from "@shared/Shell";
import Table from "@shared/Table";
import {SpinnerWrapper} from "@shared/Spinner";
import useStallholders from "@hooks/useStallholders";
import dateFormat from "dateformat";
import { stripTimeFromDate, dayBefore } from "@shared/Utils";
import { useState, useEffect, Fragment } from 'react'
import { Stallholder } from "@sharedtypes/Stallholder";
import { Switch } from '@headlessui/react'
import classnames from 'classnames';
import log from "loglevel";
import useAdminAPIService from "@hooks/useAdminAPIService";
import { Link } from "react-router-dom";
import PaymentDue from "@components/PaymentDue";
import PaymentReport from "@components/PaymentReport";
import Charges from "@components/Charges";
import DateWidget from "@components/DateWidget";
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useSearchParams } from 'react-router-dom';
import { Transition, Dialog } from '@headlessui/react'
import { StallholderPayment } from "@sharedtypes/StallholderPayment";

export function Dealers() {

  const stallholderContext = useStallholders();
  //const [endDate, setEndDate] = useState(dayBefore(new Date()));
  const [searchParams, setSearchParams] = useSearchParams();

  const [endDate, setEndDate]  = useState(() => {
    
    const paramDate = searchParams.get("date");
    if (paramDate === null ) return dayBefore(new Date()); 
    else return new Date(paramDate);
  });

  const [mark, setMark] = useState(false);
  const [processingMark, setProcessingMark] = useState(false);
  const [marks] = useState<string[]>([]);
  const apiService  = useAdminAPIService();
  const [showPaymentsReport, setShowPaymentsReport] = useState(false);
  const [showFeeDlg, setShowFeeDlg] = useState(false);
  const [payments] = useState<Map<string, StallholderPayment>>(new Map());
  const [stallholder, setStallholder] = useState<Stallholder | undefined>();
  const [chargeType, setChargeType] = useState(0);

  

  useEffect(() => {

    setSearchParams({"date": stripTimeFromDate(endDate)}) 
    return () => {
    };
  }, [endDate, setSearchParams]);  

  const columns = [
    {
      "id": undefined,
      "label": "",
      "cellStyle": (row: number) => "px-6 py-1 text-lg",
      "headerStyle": "px-6 py-4",
      "format": (cell: any, row: {}, index: number) => { 
        const target = row as Stallholder;
        return <DealerCharges stallholder={target} setStallholder={setStallholder} setShowFeeDlg={setShowFeeDlg} setChargeType={setChargeType} />;
      }
    },
    {
      "id": "dealer_id",
      "label": "ID",
      "cellStyle": (row: number) => "px-6 py-1 font-medium text-lg text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800",
      "headerStyle": "px-6 py-3 bg-gray-50 dark:bg-gray-800",
      "format": (cell: any, row: {}, index: number) => { 
        const url = "/dealer/" + cell;
        return <Link className="text-blue-400" to={url}>{cell}</Link>
      }
    },
    {
      "id": "name",
      "label": "Name",
      "cellStyle": (row: number) => "px-6 py-1 text-lg",
      "headerStyle": "px-6 py-4"
    },
    {
      "id": "lastPaymentDateCalculation",
      "label": "Last Payment includes",
      "cellStyle": (row: number) => "px-6 py-1 text-lg",
      "headerStyle": "px-6 py-4",
      "format": (cell: any, row: {}, index: number) => { 
        const date = new Date(cell);
        return dateFormat(date, "ddd, mmmm dS");
      }
    },
    {
      "id": "dealer_id",
      "label": "Due",
      "cellStyle": (row: number) => "py-1 text-lg",
      "headerStyle": "",
      "format": (cell: any, row: {}, index: number) => { 
        const stallholder = row as Stallholder;
        return <PaymentDue start={stallholder.lastPaymentDateCalculation} end={stripTimeFromDate(endDate)} stallholder={stallholder} setPayment={setPayment}/>;
      }
    },
    {
      "id": undefined,
      "label": "",
      "cellStyle": (row: number) => "px-6 py-1 text-lg",
      "headerStyle": "px-6 py-4",
      "format": (cell: any, row: {}, index: number) => { 
        const stallholder = row as Stallholder;
        return <MarkAsPaid stallholder={stallholder} defaultMark={mark} markAsPaid={markAsPaid}/>;
      }
    },
  ]

  const handleChange = (state: boolean) => {
    setMark(state);
  };

  function markAsPaid(stallholder : Stallholder, mark: boolean) {
    const index = marks.indexOf(stallholder.id);

    if (mark) {
      if (index === -1) {
        marks.push(stallholder.id);
      }
    }
    else {
      if (index !== -1) {
        marks.splice(index, 1);
      }   
    }
    log.debug("markAsPaid:", marks);
  }

  function setPayment(dealerID: string, record: StallholderPayment) {
    payments.set(dealerID, record);
  }

  function getPayments() {

    const records : StallholderPayment[] = [];
    
    marks.forEach(function (dealerID) {
      const record = payments.get(dealerID);

      if (record)
        records.push(record);
      else
        log.error("Missing payment record for dealer ", dealerID);
    });

    return records;
  }

  function openPaymentReport() {

    setShowPaymentsReport(true);
  }

  function closePaymentReport() {
    setShowPaymentsReport(false);
  }

  async function complete() {
    setProcessingMark(true);

    await apiService.fetch("recordPayment", 
        {
          payments: getPayments()
        }
      )
      .then((data) => {
        log.info("Updated payment dates " + JSON.stringify(data));
        window.location.reload();
      })
      .catch(function (error) {
        log.info("Error updating payment dates:" + error);
      }
    )
    setProcessingMark(false);
  }

  const footer = <tfoot>
                    <tr className="font-semibold text-gray-900 dark:text-white">
                      <th scope="row" className="px-6 py-3 text-base">{stallholderContext.stallholders.length} Dealers</th>
                    </tr>
                  </tfoot>

  return (
    <>
      <Shell pageIndex={1}>
        <div>
          {
            stallholderContext.stallholders.length === 0 ?
            <SpinnerWrapper /> :
            <div>
              <PaymentsDlg show={showPaymentsReport} hide={closePaymentReport} payments={getPayments()} endDate={endDate} record={complete}/>
              {
                stallholder ?
                <Charges show={showFeeDlg} date={endDate} hide={() => {setShowFeeDlg(false)}} stallholder={stallholder} type={chargeType} /> :
                <></>
              }

              <h1 className="text-2xl mb-8 mt-4">
                Payments calculated to close of business:
                <DateWidget date={endDate} setDate={setEndDate} />
              </h1>
              <div className="flex justify-end items-center mr-12 mt-10 mb-6">
                <Switch.Group as="div" className="flex items-center justify-end">
                  <span className="mr-4">
                    <Switch.Label as="span" className="mt-4 font-medium leading-6 text-gray-500" passive>
                      Toggle all dealers as paid/unpaid
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={mark}
                    onChange={handleChange}
                    className={classnames(
                      mark ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classnames(
                        mark ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
              <Table data={stallholderContext.stallholders} columns={columns} footer={footer}/>
              <div className="flex justify-end mt-10 items-center">
                <button
                  type="button"
                  disabled={processingMark}
                  className="ml-6 rounded bg-indigo-50 px-2 py-1 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 mr-2 disabled:bg-gray-400 disabled:text-gray-800"
                  onClick={()=>{openPaymentReport()}}
                  >
                  Create payment report
                </button>
              </div>
            </div>
          }
          
        </div>
      </Shell>    
    </>
  );
}

type PaymentsDlgProps = {
  show: boolean;
  hide: () => void;
  payments: StallholderPayment[];
  endDate: Date;
  record: () => void;
};

const PaymentsDlg = ({show, hide, payments, endDate, record}: PaymentsDlgProps) => {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    
    setOpen(show);
    return () => {
    };
  }, [show]);  


  function done() {
    setProcessing(true);
    record();
  }

  return (
    <div className="">
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <PaymentReport payments={payments} endDate={endDate}/>
                  <div className="mt-4">
                      <button
                        type="button"
                        className="font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={done}
                        disabled={processing}
                      >
                        Mark as paid
                      </button>
                      <button
                        type="button"
                        className="ml-4 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-100 disabled:border-red-900 border-1 border-emerald-900 p-4 text-emerald-500 shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={hide}
                        disabled={processing}
                      >
                        Cancel
                      </button>
                    </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

type MarkAsPaidProps = {
  stallholder: Stallholder;
  defaultMark: boolean;
  markAsPaid: (stallholder : Stallholder, mark: boolean) => void;
};

const MarkAsPaid = ({ stallholder, defaultMark, markAsPaid}: MarkAsPaidProps) => {

  const [mark, setMark] = useState(defaultMark);

  useEffect(() => {
    
    setMark(defaultMark);
    markAsPaid(stallholder, defaultMark);
    return () => {
    };
  }, [defaultMark]); 

  const handleChange = (state: boolean) => {
    setMark(state);
    markAsPaid(stallholder, state);
  };

  return (
    <div className="flex justify-start">
      <Switch.Group as="div" className="">
        <Switch
          checked={mark}
          onChange={handleChange}
          className={classnames(
            mark ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
        >
          <span
            aria-hidden="true"
            className={classnames(
              mark ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}

type DealerChargesProps = {
  stallholder: Stallholder;
  setStallholder: (stallholder : Stallholder ) => void;
  setShowFeeDlg: (show : boolean) => void;
  setChargeType: (type : number) => void;
};

const DealerCharges = ({ stallholder, setStallholder, setShowFeeDlg, setChargeType }: DealerChargesProps) => {

  function openFloorFee() {
    setStallholder(stallholder);
    setShowFeeDlg(true);
    setChargeType(0);
  }

  function openFloorPay() {
    setStallholder(stallholder);
    setShowFeeDlg(true);
    setChargeType(1);
  }

  function openRent() {
    setStallholder(stallholder);
    setShowFeeDlg(true);
    setChargeType(2);
  }

  return (
    <div className="flex justify-end">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="">
           
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </Menu.Button>
        </div>

        <Menu.Items
          className="absolute left-1 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <Menu.Item>
              <button
                type="submit"
                className="block w-full px-4 py-2 text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                onClick={openFloorFee}
              >
                Charge floor walking fee
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                type="submit"
                className="block w-full px-4 py-2 text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                onClick={openFloorPay}
              >
                Pay floor walking fee
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                type="submit"
                className="block w-full px-4 py-2 text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                onClick={openRent}
              >
                Add rent fee
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}


export default Dealers;
